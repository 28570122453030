.card:after{
    content: '';
    position: absolute;
    left: 0;
    width: 0%;
    bottom: 0;
    height: 7px;
    background: #0885d8;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
}
.card:hover:after{
    width: 100%;
}