/* the slides */
.slick-slide {
  padding: 0 10px;
  height: inherit;
  display: flex !important;
  flex-direction: column;
}

.slick-track{
  display: flex;
}

.slick-slide > div {
  margin-bottom: 16px;
  height: 100%;
}

.slick-slide > div:last-child {
  margin-top: auto;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px;
}

.slick-prev {
  left: -40px;
}
