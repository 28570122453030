@media (min-width: 768px) {
  #appLinks > a:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
}

@media (max-width: 767px) {
  #appLinks > a:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}
