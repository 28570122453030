.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #717171;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #262626;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: 0} 
  to {opacity: 1}
}

@media (max-width: 768px){
  #carouselImg{
    left: 50%!important;
    transform: translate(-50%, -20%);
  }
  #logoName{
    left: 50%!important;
    transform: translate(-50%, -40%);
  }
  #mainSlide{
    border-radius: 10px!important;
    margin: 0px 10px;
  }
}
