.animatedContent {
  animation: crossfade 3s linear;
}

#contactPage > *{
  transition: all .5s ease;
}

@keyframes crossfade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes crossfade {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.rightLinks:not(:last-child) {
  border-bottom: 1px solid rgb(104, 103, 103);
}
