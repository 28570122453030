@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "opensans";
  src: local("opensans"), url("./assets/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
}

body {
  font-family: "Poppins", "opensans", "Lato", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

img {
  transition: all 0.5s ease;
}

html {
  scroll-behavior: smooth;
}

#heartIcon {
  animation: infinite blink 3s linear;
}

@keyframes blink {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.downArrow {
  content: "";
  bottom: -24px;
  left: 50%;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #107dbe;
  border-bottom: 12px solid transparent;
}

@media (min-width: 768px) {
  .timeline-one:after {
    content: "";
    position: absolute;
    left: 50%;
    border-right: 1px dashed #6a849b;
    top: 0;
    height: 100%;
  }

  .timeline-one:before {
    position: absolute;
    left: 50%;
    top: -16px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #0470c6;
    content: "";
    margin-left: -6px;
  }

  .curve-line-left {
    background: transparent none repeat scroll 0 0;
    height: 90px;
    position: absolute;
    right: -25px;
    top: -50px;
    width: 80px;
  }
  .curve-line-right {
    background: transparent none repeat scroll 0 0;
    height: 32px;
    left: -20px;
    position: absolute;
    right: inherit;
    top: 68px;
    width: 73px;
  }

  .curve-line-right:before {
    border-bottom: 1px dashed #6a849b;
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 0;
    border-left: 1px dashed #6a849b;
    border-right: medium none;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 38px;
    top: 0;
  }
  .curve-line-left:before {
    border-bottom: 1px dashed #6a849b;
    border-bottom-right-radius: 80px;
    border-right: 1px dashed #6a849b;
    bottom: 0;
    content: "";
    left: 40px;
    position: absolute;
    right: 4px;
    top: 40px;
  }
}

#timeLineGrid:before {
  content: "";
  position: absolute;
  left: 0;
  border-right: 1px solid #363f4d;
  top: 0;
  height: 100%;
}
@media (min-width: 1024px) {
  #timeLineGrid:after {
    content: "";
    position: absolute;
    left: 50%;
    border-right: 1px solid#363f4d;
    top: 0;
    height: 100%;
  }
}

.timeline-card:before {
  position: absolute;
  left: 0;
  top: 20px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #0470c6;
  content: "";
  margin-left: -7px;
}

.type-four-card {
  filter: grayscale(1);
}

.type-four-card:hover {
  filter: grayscale(0);
}

.Typist .Cursor {
  display: inline-block;
}

.Typist--blinking,
.Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.video-style {
  position: absolute;
  left: 50%; /* % of surrounding element */
  top: 50%;
  transform: translate(-50%, -50%); /* % of current element */
}